<template>
  <div class="border-class pa-1">
    <v-row no-gutters align="center">
      <v-col cols="6">
        <h3>{{ $lang.labels.attributeValues }}</h3>
      </v-col>
      <v-col cols="6" class="text-right">
        <v-btn
          icon
          :outlined="$vuetify.theme.dark"
          small
          class="color-secondary bg-outline-color"
          :disabled="!canEdit"
          @click="deconstructedData.push({ name: '', value: '' }), editRow(deconstructedData.length - 1)"
        >
          +
        </v-btn>
      </v-col>
    </v-row>
    <v-row no-gutters align="center" class="pt-2">
      <v-col cols="12">
        <template v-for="(item, i) in deconstructedData">
          <v-card :key="i" flat class="mb-1 background-transparent" style="position: relative">
            <div class="d-inline-flex" style="position: absolute; top: 8px; right: 0">
              <v-btn
                icon
                small
                :disabled="!canEdit"
                @click="editRow(i)"
              >
                <v-icon color="info" small>mdi-pencil</v-icon>
              </v-btn>
              <v-btn
                class="ml-1"
                icon
                small
                :disabled="!canEdit"
                @click="deleteRow(i)"
              >
                <v-icon small color="error">mdi-trash-can-outline</v-icon>
              </v-btn>
            </div>
            <v-row no-gutters align="center" class="pb-1">
              <v-col cols="12">
                <p class="pb-0 mb-0 color-primary">{{ $lang.header.key }}</p>
                <p class="pb-0 mb-0">{{ item.name }}</p>
              </v-col>
              <v-col cols="12" class="my-1">
                <v-divider></v-divider>
              </v-col>
              <v-col cols="12">
                <p class="pb-0 mb-0 color-primary">{{ $lang.header.value }}</p>
                <p class="pb-0 mb-0">{{ item.value.length > 150 ? `${item.value.substring(0, 147)}...` : item.value }}</p>
              </v-col>
            </v-row>
          </v-card>
        </template>
      </v-col>
    </v-row>
    <v-dialog v-if="showJsGroovyEditor" v-model="showJsGroovyEditor" max-width="71%">
      <global-js-groovy-editor
        :item="selectedItem"
        :with-buttons="true"
        :can-edit="canEdit"
        :vars="steps"
        :step-type="stepType"
        :single-step="singleStep"
        @closeDialog="closeEdit()"
        @fromGlobalEditor="updateFromEditor($event)"
      />
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import globalJsGroovyEditor from '@/components/ui/GlobalJsGroovyEditor.vue'

export default {
  components: { globalJsGroovyEditor },
  props: {
    data: {
      type: Array,
      default: () => {
        return []
      }
    },
    type: {
      type: String,
      default: () => {
        return ''
      }
    },
    stepType: {
      type: String,
      default: () => {
        return ''
      }
    },
    canEdit: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    steps: {
      type: Array,
      default: () => {
        return []
      }
    },
    singleStep: {
      type: Object,
      default: () => {
        return null
      }
    }
  },
  data: () => ({
    showJsGroovyEditor: false,
    selectedItemIndex: null,
    selectedItem: {},
    deconstructedData: []
  }),
  computed: {
    ...mapState('app', ['userSettings'])
  },
  watch: {
    deconstructedData: {
      handler(val) {
        this.$emit('dataChanged', val)
      },
      deep: true
    }
  },
  created() {
    if (this.data && this.data.length > 0) {
      this.deconstructedData = this.data
    }
  },
  methods: {
    deleteRow(i) {
      this.deconstructedData.splice(i, 1)
    },
    updateFromEditor(data) {
      this.deconstructedData[this.selectedItemIndex].name = data.key
      this.deconstructedData[this.selectedItemIndex].value = data.value
      this.showJsGroovyEditor = false
      this.selectedItemIndex = null
    },
    editRow(i) {
      this.selectedItem = { text: this.deconstructedData[i].name, value: this.deconstructedData[i].value }
      this.selectedItemIndex = i
      this.showJsGroovyEditor = true
    },
    closeEdit() {
      if (this.selectedItem?.value === '' && this.selectedItem?.text === '') {
        this.deconstructedData.splice(this.selectedItemIndex, 1)
      }
      this.selectedItem = null
      this.selectedItemIndex = null
      this.showJsGroovyEditor = false
    }
  }
}
</script>
<style lang="scss" scoped>
.border-class {
  border: 1px var(--v-customInputBorderColor-base) solid;
  border-radius: 6px;
}
</style>
